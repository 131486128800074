import React from 'react';
import './dataSheet.css';
import Nav from '../nav/Nav';
import Footer from '../footer/Footer';
import mezcal1 from '../../images/7_Raíces_Mezcal3-min.jpg';
import mezcal2 from '../../images/7_Raíces_Mezcal97-min.jpg';
const DataSheet = () => {
  return (
    <>
      <div className="bg">
        <Nav />
        <div className="data-title">FICHA TÉCNICA</div>
        <div className="container-data">
          <div>
            <img src={mezcal1} alt="mezcal" className="data-img" />
          </div>
          <p className="data-text">
            Marca comercial: 7 RAÍCES DCORAZÓN
            <br />
            Producto: joven espadín
            <br />
            Tipo/categoría: blanco/joven
            <br />
            Agaves empleados: A. Angustifolia
            <br />
            Maestro mezcalero: Ambrosio Martínez
            <br />
            Crecimiento del agave: 8 a 10 años
            <br />
            Destilaciones: 2
            <br />
            Tiempo en barrica: -
            <br />
            Tipo de barrica: -
            <br />
            Tipo de horno: horno cónico de piedra
            <br />
            Tipo de molienda: molino de piedra jalada por burro
            <br />
            Tipo de destilador: alambique de cobre
            <br />
            % Alc. Vol.: 38%
            <br />
            Cont. neto: 750ml
            <br />
            Zona de origen: Santiago Matatlán, Oaxaca
          </p>
        </div>
        <div className="data-flavour">NOTAS DE CATA:</div>

        <div className="container-data-flavour">
          <p className="data-text">De cuerpo suave con tono cristalino, aroma agradable a maguey cocido con una mezcla de flores silvestres y sutiles notas frutales, ligeramente dulce pero equilibrado, sabores de agave cocido, retrogusto sabor de humo y suave al tragar.</p>
          <img src={mezcal2} alt="mezcal" className="b-img" />
        </div>
      </div>
      <Footer />
    </>
  );
};

export default DataSheet;
