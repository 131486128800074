import React from 'react';
import './ourMezcal.css';
const OurMezcal = () => {
  return (
    <>
      <div className="container-text">
        <div className="title-our-mezcal" id="ourMezcal">
          NUESTRO MEZCAL
        </div>
        <p className="text-our-mezcal">
          No olvidemos nuestras raíces, porque sin ellas no seríamos quienes somos.
          <br />
          <br />
          Nos enorgullece saber que nuestro mezcal es parte esencial de la cultura mexicana, involucrando historia, costumbres, conocimiento ancestral, tradición y arte.
          <br />
          <br />
          Mezcal 7 RAÍCES DCORAZÓN, perfecta combinación entre sabor y calidad.
          <br />
          <br />
          Hecho 100% por manos mexicanas.
        </p>
      </div>
    </>
  );
};

export default OurMezcal;
