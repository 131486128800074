import React from 'react';
import './header.css';
// import mezcal from '../images/7_Raíces_Mezcal4.jpg';

const Header = () => {
  return (
    <>
      <div className="title">7 RAÍCES</div>
      <header className="container-fluid">
        <div className="d-flex"> MEZCAL ARTESANAL </div>
      </header>
    </>
  );
};

export default Header;
