import React from "react";
import Header from "../header/Header";
import OurMezcal from "../ourmezcal/OurMezcal";
import Nav from "../nav/Nav";
import Footer from "../footer/Footer";

const Home = () => {
  return (
    <>
      <Nav />
      <Header />
      <OurMezcal />
      <Footer />
    </>
  );
};

export default Home;
