import DataSheet from "./components/datasheet/DataSheet";
import ImageGallery from "./components/gallery/ImageGallery";
import { Route, Routes } from "react-router-dom";
import Home from "./components/home/Home";
import "bootstrap/dist/css/bootstrap.min.css";

function App() {
  return (
    <div className="App">
      <>
        <Routes>
          <Route path="/" exact element={<Home />} />
          <Route path="/gallery" element={<ImageGallery />} />
          <Route path="/dataSheet" element={<DataSheet />} />
        </Routes>
      </>
    </div>
  );
}

export default App;
