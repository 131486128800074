import React from 'react';
import './imageGallery.css';
import mezcal11 from '../../images/7_Raíces_Mezcal11-min.jpg';
// import mezcal14 from '../images/7_Raíces_Mezcal14-min.jpg';
import mezcal19 from '../../images/7_Raíces_Mezcal19-min.jpg';
// import mezcal28 from '../images/7_Raíces_Mezcal28-min.jpg';
// import mezcal30 from '../images/7_Raíces_Mezcal30-min.jpg';
import mezcal33 from '../../images/7_Raíces_Mezcal33-min.jpg';
// import mezcal36 from '../images/7_Raíces_Mezcal36-min.jpg';
// import mezcal60 from '../images/7_Raíces_Mezcal60-min.jpg';
import mezcal64 from '../../images/7_Raíces_Mezcal64-min.jpg';
// import mezcal66 from '../images/7_Raíces_Mezcal66-min.jpg';
import mezcal74 from '../../images/7_Raíces_Mezcal74-min.jpg';
import mezcal79 from '../../images/7_Raíces_Mezcal79-min.jpg';
// import mezcal81 from '../images/7_Raíces_Mezcal81-min.jpg';
import mezcal95 from '../../images/7_Raíces_Mezcal95-min.jpg';
import mezcal97 from '../../images/7_Raíces_Mezcal97-min.jpg';
// import mezcal105 from '../images/7_Raíces_Mezcal105-min.jpg';
import mezcal115 from '../../images/7_Raíces_Mezcal115-min.jpg';
// import mezcal122 from '../images/7_Raíces_Mezcal122-min.jpg';
import mezcal128 from '../../images/7_Raíces_Mezcal128-min.jpg';
import Nav from '../nav/Nav';
import Footer from '../footer/Footer';

const ImageGallery = () => {
  // let imageContainerRef = useRef(null);
  const images = [mezcal11, mezcal19, mezcal33, mezcal64, mezcal74, mezcal79, mezcal95, mezcal97, mezcal115, mezcal128];
  // const prev = () => (imageContainerRef.current.scrollLeft -= 250);
  // const next = () => (imageContainerRef.current.scrollLeft += 250);
  return (
    <>
      <Nav />
      <div className="title">Galería</div>
      <div className="container-img">
        {/* TODO: Revisar para que sirve este bloque o quitarlo */}
        {/* <div className="content">
          <div className="prev" onClick={prev}>
            {" "}
            ❰{" "}
          </div>
          <div className="slide-panel" ref={imageContainerRef}>
            {images.map((image, index) => (
              <img
                key={index}
                className="img-gallery"
                alt="mezcal"
                src={image}
              />
            ))}
          </div>
          <div className="next" onClick={next}>
            ❱
          </div>
        </div> */}
        {images.map((image, index) => (
          <img key={index} className="images" alt="mezcal" src={image} />
        ))}
      </div>
      <Footer />
    </>
  );
};

export default ImageGallery;
