import React, { useState } from 'react';
import './nav.css';
import { Link } from 'react-router-dom';
import menu from '../../images/menu.svg';
import close from '../../images/cerrar.svg';
import logo from '../../images/logo7raices.png';
const Nav = () => {
  const [show, setShow] = useState(false);

  const scrollToTop = () => {
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
  };
  return (
    <>
      <nav>
        <div className="menu" onClick={() => setShow(!show)}>
          {show ? <img src={close} alt="closeimg" /> : <img src={menu} alt="menuimg" />}
        </div>
        <div>
          <Link onClick={scrollToTop} to="/">
            <img className="logo" src={logo} alt="logo" />
          </Link>
        </div>
        <ul className={show ? 'open' : ''}>
          <li>
            <Link className="listItems" to="/" onClick={scrollToTop}>
              INICIO
            </Link>
          </li>
          <li>
            <Link className="listItems" to="/gallery" onClick={scrollToTop}>
              GALERÍA
            </Link>
          </li>
          <li>
            <Link className="listItems" to="/dataSheet" onClick={scrollToTop}>
              FICHA TÉCNICA
            </Link>
          </li>
        </ul>
      </nav>
    </>
  );
};

export default Nav;
